<template>
  <div class="homePage">
    <el-container style="height: 100%">
      <!-- 左边的菜单栏 -->
      <el-aside
        class="aside"
        :width="!isCollapsing ? '212px' : '64px'"
        :style="'background-color:' + $store.state.customColors"
      >
        <Leftnav :collapsed="isCollapsing" />
      </el-aside>
      <el-container
        class="container"
        :style="{ left: !isCollapsing ? '212px' : '64px' }"
      >
        <!-- 头部 -->
        <el-header>
          <div class="header-top">
            <div class="left">
              <div class="shrinkButton" @click="isCollapsing=!isCollapsing">
                <img src="@/assets/icon/open.png" alt="icon">
              </div>
              <p class="welcome">
                采金链系统({{ systemPlatformRoles==='03'?'经销商':'代采商' }}端)<i />{{ userInfo.orgName || '' }}
              </p>
            </div>
            <!-- 是否还有消息 -->
            <el-badge :max="99" :hidden="isDot" :value="quantityInformation" class="item">
              <router-link :to="{ path: '/customerMessage' }">
                <i class="icon el-icon-message" />
              </router-link>
            </el-badge>
            <el-dropdown>
              <span class="setting">
                <img src="@/assets/image/head_portrait.png" alt="">
                <span class="user-name">
                  {{ userInfo.userName || userInfo.orgName||'' }}
                  <i
                    v-if="userInfo"
                    class="el-icon-caret-bottom el-icon--right"
                  />
                </span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <p @click="dialogFormVisible = true">
                    修改密码
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{ path: '/personalCertificate' }">
                    个人中心
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p @click="subjectDefinitionVisible = true">
                    主题定义
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p @click="exit">
                    退出登录
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="multiPageLabel">
            <el-tabs v-model="activePath">
              <el-tab-pane
                v-for="(item, index) in $store.state.multiPageLabelArr"
                :key="index + Date.now()"
                :label="item.name"
                :name="item.path"
              >
                <span slot="label">
                  <el-tag
                    closable
                    size="medium"
                    :type="activePath === item.path ? 'danger' : 'info'"
                    @close="handleClose(item.path)"
                  >
                    <router-link class="link" :to="{ path: item.fullPath }">{{
                      item.name
                    }}</router-link>
                  </el-tag>
                </span>
              </el-tab-pane>
            </el-tabs>
            <span
              class="refreshButton el-icon-refresh"
              @click="$router.go(0)"
            />
            <el-dropdown trigger="click">
              <span class="tab-close">
                <img src="../../assets/image/tab_close.svg" alt="">
              </span>
              <el-dropdown-menu slot="dropdown" class="tab-close-item">
                <el-dropdown-item>
                  <p @click="closeOther">
                    <i class="tab-close-icon close-other" />
                    关闭其他
                  </p>
                </el-dropdown-item>
                <el-dropdown-item>
                  <p @click="closeAll">
                    <i class="tab-close-icon close-all" />
                    关闭所有
                  </p>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <!-- 内容库 -->
        <el-main>
          <keep-alive>
            <router-view
              v-if="$route.meta.keepAlive"
              :class="[
                'page-container-view',
                $store.state.multiPageLabelArr.length > 0
                  ? 'intervalRouterLink'
                  : '',
              ]"
            />
          </keep-alive>
          <router-view
            v-if="!$route.meta.keepAlive"
            :class="[
              'page-container-view',
              $store.state.multiPageLabelArr.length > 0
                ? 'intervalRouterLink'
                : '',
            ]"
          />
        </el-main>
        <div v-show="showPlaceholder" class="placeholder" />
      </el-container>
    </el-container>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        status-icon
        :rules="rules"
        label-width="100px"
        class="page-form"
      >
        <el-form-item label="旧密码" prop="oldPassword1">
          <el-input v-model.number="ruleForm.oldPassword1" autocomplete="off" />
        </el-form-item>
        <el-form-item label="新密码" prop="password1">
          <el-input
            v-model="ruleForm.password1"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPassword">
          <el-input
            v-model="ruleForm.checkPassword"
            type="password"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          class="widen-button"
          type="primary"
          @click="submitForm('ruleForm')"
        >
          提交
        </el-button>
        <el-button class="widen-button" @click="dialogFormVisible = false">
          取消
        </el-button>
      </div>
    </el-dialog>
    <!-- 主题定义 -->
    <el-drawer
      title="主题选择"
      class="subjectDefinitionVisible"
      :visible.sync="subjectDefinitionVisible"
      direction="rtl"
      :modal="false"
    >
      <div class="subjectsChoiceBox">
        <div class="colorBox">
          <span
            v-for="item in colorArr"
            :key="item"
            :style="'background:' + item"
            @click="changeCustColor(item)"
          />
        </div>
        <div class="customBox">
          <p>自定义颜色:</p>
          <el-color-picker
            v-model="activeColor"
            @active-change="changeCustColor"
          />
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Leftnav from '@/components/Leftnav.vue'
import { loginOut, updatePassword } from '@/http/agentApi/login'
import { encrypt } from '@/unit/index'
import { BSERURL } from '@/config/index.js'
export default {
  components: { Leftnav },
  data() {
    var checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('旧密码不能为空'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      rules: {
        password1: [{ validator: validatePass, trigger: 'blur' }],
        checkPassword: [{ validator: validatePass2, trigger: 'blur' }],
        oldPassword1: [{ validator: checkAge, trigger: 'blur' }]
      },
      ruleForm: {},
      isDot: false,
      userInfo: {},
      quantityInformation: 0,
      activeColor: '',
      systemPlatformRoles: '03',
      colorArr: [
        'rgba(11,117,207,1)',
        'rgba(252,65,115,1)',
        'rgba(225,122,20,1)',
        'rgba(66,160,33,1)',
        'rgba(33,231,218,1)',
        'rgba(55,107,251,1)',
        'rgba(65,124,167,1)',
        'rgba(68,81,96,1)',
        'rgba(167,0,253,1)'
      ],
      subjectDefinitionVisible: false,
      activePath: '/enterpriseInformation/addInformation',
      activeFullPath: '',
      changeWidth: true,
      dialogFormVisible: false,
      isCollapsing: false,
      showPlaceholder: false
    }
  },
  computed: {
    // 用户信息
    styleColor() {
      // if (localStorage.getItem('customColors')) {
      return localStorage.getItem('customColors')
      // }
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        this.activePath = newVal.path
        this.activeFullPath = newVal.fullPath
        this.$store.commit('getmultiPageLabel', {
          path: newVal.path,
          fullPath: newVal.fullPath,
          name: newVal.meta.name || newVal.meta.title
        })
      },
      immediate: true,
      deep: true
    }
    // activeFullPath: {
    //   handler(newVal) {
    //     if (newVal) this.$router.push({ path: newVal })
    //   }
    // }
  },
  mounted() {
    this.systemPlatformRoles = sessionStorage.getItem('systemPlatformRoles')
    this.userInfo = JSON.parse(localStorage.getItem(`${this.systemPlatformRoles === '03' ? 'userInfoCust' : 'userInfoAgent'}`))
    this.tyleColor = localStorage.getItem('customColors')
    try {
      const baseUrl = BSERURL.replace('https', 'wss').replace('http', 'ws')
      const ws = new WebSocket(
        `${baseUrl}/website/websocket/${this.userInfo.cmpId}/${this.userInfo.userCode}`,
        ['superchat']
      )
      // 获取连接状态
      // 监听是否连接成功
      // ws.onopen = () => {

      // }
      ws.onmessage = (data) => {
        this.quantityInformation = Number(data.data) || 0
        this.isDot = !((Number(this.quantityInformation) || 0) > 0)
        clearTimeout(this.timer)
        this.timer = null
        // 连接成功则发送一个数据
        this.timer = setTimeout(() => {
          ws.send(
            JSON.stringify({
              userCode: this.userInfo.userCode,
              cmpId: this.userInfo.cmpId
            })
          )
        }, 10000)
      // 完成通信后关闭WebSocket连接
      // ws.close();
      }
    } catch (error) {
      console.log('websocket出错了' + error)
    }
    // console.log(localStorage.getItem('customColors'))
  },
  methods: {
    // 改变自定义颜色
    changeCustColor(color) {
      this.activeColor = color
      this.$store.commit('setCustomColors', color)
    },
    // 关闭所有
    closeAll() {
      this.$router.push({ path: '/' })
      this.$store.commit('setmultiPageLabel', [])
    },
    // 关闭其他
    closeOther() {
      const arr = this.$store.state.multiPageLabelArr
      arr.forEach((item) => {
        if (this.activePath === item.path) {
          this.$store.commit('setmultiPageLabel', [{ ...item }])
        }
      })
    },
    // 移除点击的
    handleClose(path) {
      const arr = this.$store.state.multiPageLabelArr
      if (arr.length === 1) {
        this.closeAll()
        return
      }
      let num = 0
      let flag = false
      arr.map((item, index) => {
        if (item.path === path) {
          arr.splice(index, 1)
        }
        // 判断删除的这个是不是当前active的
        if (this.activePath === item.path && this.activePath === path) {
          num = index
          flag = true
        }
      })
      if (flag) {
        this.$router.push({
          path:
            (arr[num - 1] && arr[num - 1].path) ||
            (arr[num + 1] && arr[num + 1].path) ||
            '/enterpriseInformation/addInformation'
        })
      }
      this.$store.commit('setmultiPageLabel', arr)
    },
    // 修改密码确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const passwordObj = {
            oldPassword: encrypt(this.ruleForm.oldPassword1 + ''),
            password: encrypt(this.ruleForm.password1)
          }
          updatePassword(passwordObj, () => {
            this.dialogFormVisible = false
            this.$message.success('修改成功！')
          })
        }
      })
    },
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          loginOut({}, (res) => {
            setTimeout(() => {
              this.$router.push({ path: '/login' })
            }, 1000)
            sessionStorage.setItem('tokenAgent', null)
            localStorage.setItem('userInfoAgent', null)
            res.code === 200
              ? this.$message.success(res.msg)
              : this.$message.error(res.msg)
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    }
  }
}
</script>

<style lang="scss">
.homePage {
  height: 100%;
  background: url("../../assets/image/main_bg.png");
  .container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    transition: 0.5s all;
  }
  .aside {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
  .item {
    flex: 1;
    text-align: right;
    margin-right: 16px;
    .icon {
      cursor: pointer;
      font-size: 20px;
      color: #000;
    }
  }
  .placeholder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.2);
    z-index: 9;
  }
  .subjectDefinitionVisible {
    .subjectsChoiceBox {
      display: flex;
      align-items: left;
      flex-direction: column;
      padding: 0 24px;

      .customBox {
        display: flex;
        align-items: center;

        p {
          color: $mainFontColor;
          margin-right: 12px;
          font-weight: 700;
        }
      }

      .colorBox {
        display: flex;
        justify-content: space-between;
        margin: 24px 0;

        span {
          display: inline-block;
          height: 36px;
          width: 36px;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }

    .el-dialog__header {
      display: none;
    }

    .el-drawer__header {
      margin-bottom: 12px;

      > span {
        color: $mainFontColor;
        font-weight: 900;
        font-size: 18px;
      }
    }
  }

  .el-header {
    position: relative;
  }

  .header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px 0 16px;
    background-color: #fff;
    height: 56px;
    .left{
      display: flex;
      align-items: center;
    }
    .shrinkButton{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      width: 32px;
      cursor: pointer;
      >img{
        display: block;
        height: 32px;
        width: 32px;
      }
    }
  }

  .multiPageLabel {
    position: absolute;
    bottom: -6px;
    width: calc(100% - 34px);
    height: 38px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 16px;

    .el-tabs {
      width: calc(100% - 96px);
      height: 100%;

      .el-tabs__nav-wrap {
        .el-tabs__nav-prev {
          box-shadow: 12px 12px 12px #f2f2f2;
        }

        .el-tabs__nav-next {
          box-shadow: -12px 0 12px #f2f2f2;
        }
      }

      .el-tabs__item {
        border: 1px solid #fff;
        margin-right: 8px;
        padding: 0 !important;

        .icon {
          height: 8px;
          width: 8px;
          border-radius: 4px;
          display: inline-block;
          background-color: #ccc;
          margin: 0 4px;
        }
      }
    }

    .link {
      color: $mainFontColor;
      text-decoration: none;
      display: inline-block;
      height: 100%;
    }

    .refreshButton,
    .tab-close {
      display: inline-block;
      cursor: pointer;
      width: 38px;
      height: 34px;
      border-radius: 4px;
      background: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      img {
        width: 18px;
        height: 20px;
      }
    }
  }

  .setting {
    cursor: pointer;
    margin-left: 16px;
    img{
      height: 32px;
      width: 32px;
    }

    .user-name {
      margin-left: 12px;
      font-style: normal;
      color: #666;
    }
  }

  .el-aside {
    color: #fff;
    transition: all 0.5s;
    overflow-x: hidden;
  }
}
</style>
