/**
* 左边菜单
*/
<template>
  <div>
    <el-menu
      :collapse="collapsed"
      collapse-transition
      router
      :default-active="defaultActive"
      unique-opened
      class="el-menu-vertical-demo"
      :background-color="$store.state.customColors"
      text-color="#2E2E2E"
      active-text-color="#007EFF"
    >
      <div class="logobox" :style="'border-color:' + $store.state.customColors">
        <router-link class="logo" :to="{path:'/'}">
          <img v-if="collapsed" class="logoimg" :src="$localPicture+'logo/logo_small.png'" alt>
          <img v-else class="logoimg logoimg2" :src="$localPicture+'logo/logo_black.png'" alt>
          <!-- <p v-show="collapsed">
            {{ systemPlatformRoles==='03'?'经销商':'代采商' }}
          </p> -->
        </router-link>
      </div>
      <div v-for="menu in allmenu" :key="menu.resCode" :class="collapsed ? 'menu-hide': 'menu-display'">
        <el-submenu v-if="menu.resOperType === '1'" :index="menu.resCode" :class="menu.resUrl === '/salesContractManagement/index' ? submenuCode : ''">
          <template slot="title">
            <div @click="swatchSubmenu(menu.resUrl, menu.resCode)">
              <img v-if="menu.resUrl === submenuUrl" class="icon" :src="getHoverImgUrl(menu.icon)" alt="">
              <img v-else-if="getIsHover(menu.children)" class="icon" :src="getImgUrl(menu.icon)" alt="icon">
              <img v-else class="icon" :src="getHoverImgUrl(menu.icon)" alt="icon">
              <span v-show="!collapsed">{{ menu.resName }}</span>
            </div>
          </template>
          <el-menu-item-group>
            <div v-for="chmenu in menu.children" :key="chmenu.resCode">
              <el-menu-item v-if="chmenu.resOperType === '2'" :index="chmenu.resUrl">
                <div @click="swatchSubmenu(chmenu.resUrl, chmenu.resCode)">
                  <!-- <i :class="chmenu.icon||'el-icon-s-order'" /> -->
                  <span>{{ chmenu.resName }}</span>
                </div>
              </el-menu-item>
            </div>
          </el-menu-item-group>
        </el-submenu>
        <!-- 直接是没有子孩子的 -->
        <el-menu-item v-else-if="menu.resOperType === '2'" :index="menu.resUrl">
          <div @click="swatchSubmenu(menu.resUrl, menu.resCode)">
            <img v-if="defaultActive!==menu.resUrl" class="icon" :src="getImgUrl(menu.icon)" alt="icon">
            <img v-else class="icon" :src="getHoverImgUrl(menu.icon)" alt="icon">
            <span v-show="!collapsed">{{ menu.resName }}</span>
          </div>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>
<script>
import { getAllPermission } from '@/http/agentApi/institutionalFramework'
import { BSERURL } from '@/config/index'
export default {
  name: 'Leftnav',
  props: {
    collapsed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      allmenu: [],
      defaultActive: '',
      isCollapsing: false,
      allPermissionList: [],
      systemPlatformRoles: null,
      submenuCode: '',
      submenuUrl: ''
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        this.defaultActive = newVal.meta.parentPath || sessionStorage.getItem('defaultActivePath')
        sessionStorage.setItem('defaultActivePath', newVal.meta.parentPath || sessionStorage.getItem('defaultActivePath'))
      },
      immediate: true,
      deep: true
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // 这个websocket的是判断有没有相同的登录的
    this.systemPlatformRoles = sessionStorage.getItem('systemPlatformRoles') || '03'
    if (this.systemPlatformRoles) this.$store.commit('changeSystemPlatformRoles', this.systemPlatformRoles)
    try {
      const baseUrl = BSERURL.replace('https', 'wss').replace('http', 'ws')
      var ws = new WebSocket(
        `${baseUrl}/websocket/${sessionStorage.getItem(
          `${this.systemPlatformRoles === '03' ? 'tokenCust' : 'tokenAgent'}`
        )}`,
        ['superchat']
      )
      // 获取连接状态
      ws.onmessage = () => {
        this.$alert('您的账号已经在其他设备上登录', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          callback: () => {
            this.$router.push({ path: '/login' })
            // 完成通信后关闭WebSocket连接
            ws.close()
          }
        })
      }
    } catch (err) {
      console.log('websocket出错了' + err)
    }
    // 从缓存中取出所拥有的的权限  进行判断他有那些菜单
    // const permission = JSON.parse(localStorage.getItem('userdata')).permission || []
    // const arr = []
    // // 取唯一标识
    // permission.forEach(item => {
    //   arr.push(item.resCode)
    // })
    getAllPermission(res => {
      // 取出相同的进行渲染
      // const owningMenus = res.data.filter(item => {
      //   return arr.includes(item.resCode)
      // })
      const data = [...res.data]
      if (data.length > 0 && data[0].children) {
        this.allmenu = [...data[0].children]
        this.getAllPermissionList(data[0].children)
        this.$store.commit('getAllPermissionList', this.allPermissionList)
      }
    }, () => { }, this.systemPlatformRoles)
  },
  methods: {
    // 获取图片
    getImgUrl(url) {
      const src = `${url}.png`
      return require(`../assets/menuIcon/${src}`)
    },
    getHoverImgUrl(url) {
      const src = `${url}_hover.png`
      return require(`../assets/menuIcon/${src}`)
    },
    getIsHover(arr = []) {
      if (arr.length === 0) return true
      return !arr.some(item => item.resUrl === this.defaultActive)
    },
    // 把所拥有的权限转为一维数组
    getAllPermissionList(arr) {
      if (!arr || arr.length === 0) return
      arr.forEach(item => {
        this.allPermissionList.push(item)
        if (item.children && item.children.length > 0) {
          this.getAllPermissionList(item.children)
        }
      })
    },
    swatchSubmenu(url, code) {
      if (url === '/salesContractManagement/index') this.$router.push(url)
      this.submenuCode = code
      if (!url) return
      this.submenuUrl = url.indexOf('?') !== -1 ? url.split('?')[0] : url
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu--collapse {
  width: 85px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item,/deep/.el-submenu__title{
  height: 44px;
  line-height: 44px;
  margin-bottom: 8px;
}
.el-menu-item .icon,.el-submenu .icon {
  height: 24px;
  width: 24px;
  margin: 0 8px 2px 4px;
}
.el-menu-item span,.el-submenu span{
  color: #333;
  font-size: 16px;
  font-weight: 400;
}
/deep/.el-menu-item-group__title {
  padding: 0px!important;
  height: 0;
  display: none!important;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
.logobox {
  display: flex;
  align-items: flex-end;
  color: #000000;
  font-size: 18px;
  text-align: center;
  height: 56px;
  font-weight: bold;
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 24px;
  .logo {
    display: flex;
    align-items: center;
    color: $mainFontColor;

  }
  .logoimg{
    margin-left: 12px;
    height: 39px;
    transition: all .5s;
  }
  .logoimg2{
    height: 39px;
    width: 158px;
    margin-left: 22px;
  }
  .icon {
    width: 23px;
    height: 23px;
    cursor: pointer;
    display: inline-block;
    transition: 0.4s;
  }

  .menu-open {
    background: url('../assets/image/menu_open.svg') no-repeat top center;
    &:hover {
      background: url('../assets/image/menu_open_a.svg') no-repeat top center;
    }
  }
  .menu_stow {
    background: url('../assets/image/menu_open.svg') no-repeat top center;
    transform: rotateY(180deg);
    &:hover {
      background: url('../assets/image/menu_open_a.svg') no-repeat top center;
    }
  }
}

.menu-hide {
 /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}

.menu-display {
  /deep/ .el-submenu__icon-arrow {
    display: block;
  }
}
.el-submenu .el-menu-item{
  padding-left: 56px!important;
}

</style>
